import { gsap } from 'gsap';
import EventEmitter from '../Utils/EventEmitter';

export default class VideoPortalMobile extends EventEmitter {
    constructor() {
        super();

        this.page = document.getElementById('videoPortalMobile');
        this.video = document.getElementById('videoPortalTag');

        this.video.addEventListener('ended', this.handleVideoEnded.bind(this));

        // gsap.set(this.video, { opacity: 0 });
    }

    show() {
        // console.log('video mobile show');
        this.page.classList.add('section--active');
        // console.log('Show section Video Portal Mobile');
        
        this.fadeInAndPlay();
    }

    hide() {
        // console.log('video mobile hide');

        this.page.classList.remove('section--active');
        // console.log('Hide section Video Portal Mobile');
        
        this.video.pause();
        this.video.currentTime = 0;
    }

    fadeInAndPlay() {
        // console.log('video mobile fadeInAndPlay');
        // console.log('play portal video');
        this.playVideo();
        // gsap.to(this.video, {
        //     opacity: 1,
        //     duration: 1, 
        //     ease: 'power2.inOut',
        //     onComplete: () => {
        //         console.log('play portal video');
        //         this.playVideo();
        //     }
        // });
    }

    playVideo() {
        this.video.play();
        // function handleEvent(event) {
        //     console.log(event.type);
        // }
          
        // this.video.addEventListener("loadstart", handleEvent);
        // this.video.addEventListener("progress", handleEvent);
        // this.video.addEventListener("canplay", handleEvent);
        // this.video.addEventListener("canplaythrough", handleEvent);
        // this.video.addEventListener("ended", handleEvent);
        // this.video.addEventListener("error", handleEvent);
        // this.video.addEventListener("stalled", handleEvent);
        // this.video.addEventListener("waiting", handleEvent);
        setTimeout(()=>{
            this.checkVideoPlaying() ? null : this.trigger('videoEnded');     
        }, 6000)
    }

    handleVideoEnded() {
        // console.log('video mobile ended');
        this.trigger('videoEnded');
    }
    checkVideoPlaying(){
        if(this.video.currentTime > 0 && !this.video.paused && !this.video.ended && this.video.readyState > 2){
            // console.log('video is playing');      
            return true
        } else {
            return false
        }
    }

}
