uniform float uTime;
uniform float u_alpha;
uniform sampler2D uBranding;

varying vec2 v_uv;

void main()
{
    float alpha = u_alpha;
    // Scale and animate
    // vec2 smokeUv = vUv;
    // smokeUv.x *= 0.5;
    // smokeUv.y *= 0.3;
    // smokeUv.y -= uTime * 0.03;

    // Smoke
    // float smoke = texture(uPerlinTexture, smokeUv).r;

    // Remap
    // smoke = smoothstep(0.4, 1.0, smoke);

    // Edges
    // smoke *= smoothstep(0.0, 0.1, vUv.x);
    // smoke *= smoothstep(1.0, 0.9, vUv.x);
    // smoke *= smoothstep(0.0, 0.1, vUv.y);
    // smoke *= smoothstep(1.0, 0.4, vUv.y);

    // Final color
    vec4 branding = texture(uBranding,v_uv);

    gl_FragColor = vec4(branding.xyz, branding.a * alpha);
    // gl_FragColor = vec4(1.0,1.0,0.0,1.0);
}