varying vec2 vUv;
varying vec4 vPosition;
uniform float uTime;
uniform float uOpacity;



void main() {
    float time = uTime / 1000.0;
    float opacity = uOpacity;
    gl_FragColor = vec4(1);
    float dist = distance(vUv,vec2(0.5))*2.;
    float mask = cos( mod(dist*2. - time * 0.4, 1.0) * 3.14158 ) ;
    float alpha = mix(0.7,mask*.5,smoothstep(0.28, 0.3, dist))*(1.0 - dist);
    mask = mix(1.,mask*.5,smoothstep(0.28, 0.3, dist));
    gl_FragColor = vec4(vec3(mask),alpha * opacity);
}