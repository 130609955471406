import { gsap } from 'gsap';
import EventEmitter from '../Utils/EventEmitter';

export default class VideoHidden extends EventEmitter {
    constructor() {
        super();

        this.page = document.getElementById('videoHidden');
        this.video = document.getElementById('videoHiddenTag');

        this.video.addEventListener('ended', this.handleVideoEnded.bind(this));

        gsap.set(this.video, { opacity: 0 });
    }

    show() {
        this.page.classList.add('section--active');
        // console.log('Show section Video Hidden');
        
        this.fadeInAndPlay();
    }

    hide() {
        this.page.classList.remove('section--active');
        // console.log('Hide section Video Hidden');
        
        this.video.pause();
        this.video.currentTime = 0;
    }

    fadeInAndPlay() {
        gsap.to(this.video, {
            opacity: 1,
            duration: 1, 
            ease: 'power2.inOut',
            onComplete: () => {
                // console.log('play portal video');
                this.playVideo();
            }
        });
    }

    playVideo() {
        this.video.play();
    }

    handleVideoEnded() {
        this.trigger('videoEnded');
    }

}
