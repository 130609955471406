import * as THREE from 'three';
// import Experience from '../Experience/Experience';
import BaseElement from './Elements/BaseElement';
// import { Vector2 } from 'three';
import Engine from '../Experience/Utils/Engine';
import dotVertexShader from '../Experience/Home/shaders/dot/vertex.glsl'
import dotFragmentShader from '../Experience/Home/shaders/dot/fragment.glsl'
import gsap from 'gsap';




export default class Hotspot extends BaseElement {
  constructor(_name, _degreesX = -131, _degreesY = -15, x = 5, y = 5) {
    super(_name)

    let degreesY = _degreesX
    let degreesX = _degreesY
    let sizeX = x
    let sizeY = y

    this.engine = new Engine()
    this.time = this.engine.time
    this.camera = this.engine.camera
    // this.experience = new Experience()
    // this.time = this.experience.time



    this.mesh = new THREE.Mesh(
      new THREE.PlaneGeometry(sizeX, sizeY, 4, 4),
      new THREE.MeshStandardMaterial({
        color: 'red', 
        side: THREE.DoubleSide, 
        transparent: true, 
        opacity: 0, 
        // false para que quede al frente
        depthTest: false, 
        depthWrite: false,
      })
    );
    this.mesh.position.x = -40
    this.element.rotation.y = degreesY * (Math.PI/180)
    this.element.rotation.x = degreesX * (Math.PI/180)
    this.element.name = _name
    this.mesh.name = _name
    this.element.renderOrder = 300;

    this.element.add(this.mesh)


    // hotspot resaltador
    this.dotElement = new THREE.Group()

    this.dot = new THREE.Mesh(
      new THREE.PlaneGeometry(0.4, 0.4, 4, 4),
      new THREE.ShaderMaterial({
        vertexShader: dotVertexShader,
        fragmentShader: dotFragmentShader,
        uniforms:
        {
            uTime: { value: 0},
            uOpacity: { value: 0},
        },
        side: THREE.DoubleSide,
        transparent: true,
        depthWrite: true,
        depthTest: false,
        // wireframe: true
    })
    );

    this.dot.position.x = -6
    // this.dot.position.y += 0.15
    // this.dot.position.z -= 0.2
    this.dot.name = _name + '_dot'
    this.dot.renderOrder = 800;
    this.dotElement.rotation.y = degreesY * (Math.PI/180)
    this.dotElement.rotation.x = degreesX * (Math.PI/180)
    this.dotElement.add(this.dot)

    this.time.on('runFrame', () =>
      {
          this.update()
      })
  }

  showDot(){
    setTimeout(() => {
      gsap.to(this.dot.material.uniforms.uOpacity, { 
          duration: 2, 
          value: 1.0, 
      })
    }, 2000);
  }
  moveDot(x=0,y=0){
    this.dot.position.y = y
    this.dot.position.z = x
  }


  update(){
    // if(!this.experience.active) return
    // console.log('update');
    this.mesh.lookAt(this.camera.instance.position)
    this.dot.lookAt(this.camera.instance.position)
    // console.log(this.dot);
    this.dot.material.uniforms.uTime.value = this.time.elapsed
    // this.element.rotation.y += 0.001

    // this.raycaster.setFromCamera(this.mousePosition,this.experience.camera.instance);
    // const intersects = this.raycaster.intersectObjects( this.experience.scene.children );

    // for ( let i = 0; i < intersects.length; i ++ ) {

    //   if(intersects[i].object.name === this.mesh.name){
    //     // console.log(intersects[i]);
    //     // console.log('mesh click');
    //     // console.log(i);
    //     // console.log(`over ${this.name}`);
    //     // this.trigger('hotspot_click');

    //   }
    // }

  }

}
