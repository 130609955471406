export default [
    // {
    //     name: 'environmentMapTexture',
    //     type: 'cubeTexture',
    //     path:
    //     [
    //         'textures/environmentMap/px.jpg',
    //         'textures/environmentMap/nx.jpg',
    //         'textures/environmentMap/py.jpg',
    //         'textures/environmentMap/ny.jpg',
    //         'textures/environmentMap/pz.jpg',
    //         'textures/environmentMap/nz.jpg'
    //     ]
    // },
    // {
    //     name: 'coronacap',
    //     type: 'texture',
    //     path: 'textures/test/coronacap.png'
    // },
    // {
    //     name: 'grassColorTexture',
    //     type: 'texture',
    //     path: 'textures/dirt/color.jpg'
    // },
    // {
    //     name: 'grassNormalTexture',
    //     type: 'texture',
    //     path: 'textures/dirt/normal.jpg'
    // },
    {
        name: 'panoramaImage',
        type: 'texture',
        // path: 'textures/panorama/panorama0701.jpg'
        path: 'textures/panorama/panorama0820.jpg'
        // path: 'textures/panorama/Final360render.png'
    },
    {
        name: 'panoramaNoise',
        type: 'texture',
        path: 'textures/noise.png'
    },
    {
        name: 'bottleMask',
        type: 'texture',
        path: 'textures/bottle_mask.png'
    },
    {
        name: 'coronaBranding',
        type: 'texture',
        path: 'textures/coronaBranding.png'
    },
    {
        name: 'bMask',
        type: 'texture',
        path: 'textures/bmask.jpg'
    },
    {
        name: 'hMask',
        type: 'texture',
        // path: 'textures/hotspotMask.jpg'
        path: 'textures/hotspotMask_pia_12.png'
    },
    {
        name: 'perlin',
        type: 'texture',
        path: 'textures/perlin.png'
    },
    {
        name: 'bobble',
        type: 'gltfModel',
        animations: ['Cylinder.002Action.001'],
        path: 'models/PesoPlumaModel_BakeV2.glb'
    },

]