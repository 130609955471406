import { gsap } from 'gsap';
import EventEmitter from '../Utils/EventEmitter';

export default class VideoIntroDesktop extends EventEmitter {
    constructor() {
        super();

        this.page = document.getElementById('videoIntroDesktop');
        this.videoWrapper = document.querySelector('.video-intro__wrapper');
        this.video = document.getElementById('videoTagDesktop');

        this.video.addEventListener('ended', this.handleVideoEnded.bind(this));

        gsap.set(this.videoWrapper, { opacity: 0 });
    }

    show() {
        this.page.classList.add('section--active');
        console.log('Show section Video Intro Desktop');
        
        this.sectionInAnimation();
    }

    hide(onComplete) {
        //this.page.classList.remove('section--active');
        this.sectionOutAnimation(() => {
            this.page.classList.remove('section--active');
            console.log('Hide section Video Intro Desktop');
            if (onComplete) onComplete();
        });
    }

    sectionInAnimation() {
        gsap.to(this.videoWrapper, {
            opacity: 1,
            duration: 1, 
            ease: 'power2.inOut',
            onComplete: () => {
                this.playVideo();
            }
        });
    }

    sectionOutAnimation(onComplete) {
        gsap.to(this.videoWrapper, {
            opacity: 0,
            duration: 0.5,
            ease: 'power2.in',
            onComplete: () => {
                this.pauseVideo();
                if (onComplete) onComplete();
            }
        });
    }

    playVideo() {
        this.video.play();
    }

    pauseVideo() {
        this.video.pause();
        this.video.currentTime = 0;
    }

    handleVideoEnded() {
        this.trigger('videoEnded');
    }

}
