import SitePreloader from './Utils/SitePreloader.js'
import AgeGate from './Pages/AgeGate.js'
import VideoIntroDesktop from './Pages/VideoIntroDesktop.js'
import VideoPortalMobile from './Pages/VideoPortalMobile.js'
import VideoARMobile from './Pages/VideoARMobile.js'
import VideoHidden from './Pages/VideoHidden.js'
import QRDesktop from './Pages/QRDesktop.js'
import DataFormMobile from './Pages/DataFormMobile.js'
import WebExperience from './WebExperience.js'
import SoundButton from './SoundButton.js'
import { gsap } from 'gsap';

let instance = null

function isMobile() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|android|iphone|ipad|iemobile|wpdesktop|windows phone|webos/i.test(userAgent);
}

export default class App {
    constructor()  {
        const body = document.querySelector('body');
        
        // Singleton
        if(instance) {
            return instance
        }
        instance = this
                
        this.active = true;
        this.isMobile = isMobile();
        this.ageGate = new AgeGate();

        this.preloaderScreen = document.getElementById('sitePreloader');

        // test actions
        // console.log(navigator.connection.downlink);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let testString = urlParams.get('test')

        if(testString!=''){
            const preloaderElement = document.getElementById('sitePreloader');
            preloaderElement.style.display = 'none';
            const experienceElement = document.getElementById('experienceContainer');
            experienceElement.style.display = 'block';
        }

        if(testString === 'stage'){
            this.webExperience = new WebExperience()
            this.webExperience.userCam.init()
            this.webExperience.testStage()

            return
        }
        if(testString === 'home'){
            this.webExperience = new WebExperience()
            this.webExperience.userCam.init()
            this.webExperience.testHome()

            return
        }

        if (this.isMobile) {
            body.classList.add('is-mobile');
            // video
            this.videoPortalMobile = new VideoPortalMobile();
            this.videoARMobile = new VideoARMobile();
            this.mobileForm = new DataFormMobile();

        } else {
            this.videoIntroDesktop = new VideoIntroDesktop();
            this.qrDesktop = new QRDesktop();
        }

        this.ageGate.on('ageOk', this.onAgeOk.bind(this));
        if (!this.isMobile) {
            this.videoIntroDesktop.on('videoEnded', this.onVideoIntroDesktopEnded.bind(this));
        } else {
            this.mobileForm.on('formValid', this.onMobileFormOk.bind(this))
        }



        this.preloadResources();

    }

    preloadResources() {
        // console.log('preload resources');
        const resources = [
            // '/videos/demo_360.mp4',
            '/images/intro.jpg',
            '/images/intro_motion.jpg',
            '/images/intro_mobile.jpg',
            '/images/intro_mobile_motion.jpg',
            '/images/console.jpg',
            '/images/blur.jpg',
            '/audio/bw.mp3'
        ];

        new SitePreloader(resources, this.onPreloadComplete.bind(this));
    }

    onPreloadComplete() {
        const preloaderElement = document.getElementById('sitePreloader');
        const experienceElement = document.getElementById('experienceContainer');

        preloaderElement.style.display = 'none';
        experienceElement.style.display = 'block';

        this.videoHidden = new VideoHidden();
        // this.videoHidden.on('videoEnded', this.onMobileVideoHiddenEnded.bind(this));
        


        this.ageGate.show();
    }

    onAgeOk() {
        this.ageGate.hide();
        if (this.isMobile) {
            this.mobileForm.show();

            // web experience
            this.webExperience = new WebExperience()

        } else {
            this.videoIntroDesktop.show();
        }
    }

    onVideoIntroDesktopEnded() {
        console.log('Video ended, transitioning to QR section');
        this.videoIntroDesktop.hide(() => {
            this.qrDesktop.show();
        });
    }

    onMobileFormOk(){

        this.videoPortalMobile.show();

        const preloaderElement = document.getElementById('sitePreloader');

        this.mobileForm.hide()
        // // preloaderElement.style.display = 'flex';
        this.videoPortalMobile.on('videoEnded', this.onMobilePortalVideoEnded.bind(this));



    }

    onMobilePortalVideoEnded(){

        console.log('video endend mobile');
        // let target = this

        gsap.fromTo(this.preloaderScreen.style, { opacity: 0 }, { opacity: 1, duration: .5, ease: 'power2.out', onComplete: () =>{
            this.videoPortalMobile.hide();

            // show experience
            // console.log(this.webExperience);
            this.webExperience.show()
            this.webExperience.home.homeObjects.rotation.y = this.webExperience.cameraControl.docGranted ? -Math.PI / 2 : 0;
    
            this.soundButton = new SoundButton()
            setTimeout(() => {
                this.soundButton.show()
    
                gsap.fromTo(this.soundButton.getElement(), { opacity: 0 }, { opacity: 1, duration: 1, ease: 'power2.out' });
    
    
             }, 1000);

            gsap.to(this.preloaderScreen.style, { opacity: 0, duration: 3, ease: 'power2.out', onComplete: ()=>{ this.preloaderScreen.style.display = 'none';} });

    
        } });

        this.preloaderScreen.style.display = 'flex';






        //  setTimeout(() => {
        //     console.log('show preloader');
        //     gsap.fromTo(this.preloaderScreen.style, { opacity: 0 }, { opacity: 1, duration: 1, ease: 'power2.out' });
        //     this.preloaderScreen.style.display = 'flex';

        // }, 2500);
        // setTimeout(() => {
        //     console.log('show preloader');
        //     gsap.to(this.preloaderScreen.style, { opacity: 0, duration: 1, ease: 'power2.out', onComplete: ()=>{ this.preloaderScreen.style.display = 'none';} });

        // }, 5000);


    }

    onMobileARVideoEnded(){
        this.videoARMobile.hide();

        this.webExperience.showStage()


    }

    onMobileVideoHiddenEnded(){
        this.videoHidden.hide()
        // console.log('video hidden ended');
        
    }

}