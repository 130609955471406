import * as THREE from 'three'
import { gsap } from 'gsap'
import EventEmitter from '../Utils/EventEmitter'
import iceVertexShader from './shaders/ice/vertex.glsl'
import iceFragmentShader from './shaders/ice/fragment.glsl'
import WebExperience from '../WebExperience'


export default class IceSmoke extends EventEmitter
{
    constructor(_perlin)
    {
        super()

        this.experience = new WebExperience()
        this.time = this.experience.time
    

        this.container = new THREE.Group()
        this.container.position.x = 3
        this.container.rotation.y = 62 * (Math.PI/180)
        this.container.rotation.x = 0 * (Math.PI/180)

    

        this.smokeGeometry = new THREE.PlaneGeometry(1, 1, 16, 64)
        this.smokeGeometry.translate(-1, 0, 1)
        this.smokeGeometry.scale(1, 1, 1)
        this.smokeGeometry.rotateZ(0.12)
        this.smokeGeometry.rotateY(Math.PI/2)

        // Perlin texture
        this.perlinTexture = _perlin
        this.perlinTexture.wrapS = THREE.RepeatWrapping
        this.perlinTexture.wrapT = THREE.RepeatWrapping

        // Material
        this.smokeMaterial = new THREE.ShaderMaterial({
            vertexShader: iceVertexShader,
            fragmentShader: iceFragmentShader,
            uniforms:
            {
                uTime: new THREE.Uniform(0),
                uOpacity: new THREE.Uniform(0),
                uPerlinTexture: new THREE.Uniform(this.perlinTexture)
            },
            side: THREE.DoubleSide,
            transparent: true,
            depthWrite: true,
            depthTest: false,
            // wireframe: true
        })

        this.newMat = new THREE.MeshStandardMaterial({
            color: 0xff0000,
            transparent: true,
            opacity: 0.8,
            side: THREE.DoubleSide,
            // depthWrite: false,
            // depthTest: true,

        })

        // Mesh
        this.smoke = new THREE.Mesh(this.smokeGeometry, this.smokeMaterial)
        this.smoke.position.x = -9
        
        this.smoke.renderOrder = 100

        this.container.add(this.smoke)


        this.time.on('runFrame', () =>
            {
                this.update()
            })


    }
    showSmoke(){
        setTimeout(() => {
          gsap.to(this.smokeMaterial.uniforms.uOpacity, { 
              duration: 2, 
              value: 1.0, 
          })
        }, 2000);
      }

    update(){
        // this.smoke.lookAt(this.experience.camera.instance.position)
        this.smokeMaterial.uniforms.uTime.value = this.experience.time.elapsed / 250
    }
}