varying vec2 v_uv;
uniform float u_time;
uniform float u_mix;
uniform float u_opacity;
uniform float u_transition;
uniform float u_scale;
uniform float u_scale_loop;
uniform sampler2D u_map;
uniform sampler2D u_maskBottle;
uniform sampler2D u_noise;
uniform sampler2D u_bMask;
uniform sampler2D u_hMask;

#define TAU 6.2831853


void main() {

    float time = u_time / 2.0;
    float mixValue = u_mix;
    float opacityValue = u_opacity;
    float scaleValue = u_scale;
    float scaleLoopValue = u_scale_loop;
    float transition = u_transition;

    vec4 bottle = texture(u_maskBottle,v_uv);

    vec2 vUv = v_uv;

    // Punto central
    float center = 0.5;
    
    // Rango donde aplicar la escala
    // float scaleRange = 0.5 * scaleValue * scaleLoopValue;
    float scaleRange = 0.5;

    // Escalado horizontal
    // if (vUv.x > center - scaleRange && vUv.x < center + scaleRange) {
    //     float distanceFromCenter = (vUv.x - center) / scaleRange;
    //     vUv.x = center + distanceFromCenter * 0.5; // Escala por 2
        
    // }
    float distanceFromCenter = (vUv.x - center) / scaleValue * scaleLoopValue * 2.0;
    vUv.x = center + distanceFromCenter * 0.5; // Escala por 2

    // Escalado vertical
    // if (vUv.y > center - scaleRange && vUv.y < center + scaleRange) {
    //     float distanceFromCenter = (vUv.y - center) / scaleRange;
    //     vUv.y = center + distanceFromCenter * 0.5; // Escala por 2

    // }
    float distanceFromCenterY = (vUv.y - center) /  scaleValue * scaleLoopValue * 2.0;
    vUv.y = center + distanceFromCenterY * 0.5; // Escala por 2

    vec4 qmask = texture(u_maskBottle,vUv);

    // gl_FragColor = vec4(map.rgb, mix(map.a, qmask.a, mixValue));
    // float alpha = sin(time*5.)*.5+.5; //periodo de 0 a 1


    // vec2 palmAnimUvs = v_uv;
    vec4 bMask = texture(u_bMask, vUv);
    vec4 hMask = texture(u_hMask,v_uv);
    // vec4 map = texture(u_map,v_uv);
    // vec4 maskedImage = vec4(map.rgb, qmask.a);

    // if (hMask.b>0.) {
    //   vec2 palmAnimUvs = v_uv;
    //   float mov = sin(time*2.)*.5+.5*(hMask.b*.1); //periodo de 0 a 1
    //   // vec2 palmAnimUvs = v_uv;
    //   palmAnimUvs.x += mov*.001;//ajustar multiplier y agregar random o algo para que no se muevan todos iguales
    //   palmAnimUvs.y += mov*.001;
    //   vec4 map = texture(u_map,palmAnimUvs);
    //   vec4 maskedImage = vec4(map.rgb, qmask.a);
    //   vec4 oGlow = vec4(0.9,0.5,0.3,0.5);
    //   float world = 3.0;
    //   vec4 mask = bMask;
    //   vec4 noise = texture(u_noise, vUv*3.7+time*0.1);
    //   vec4 noise2 = texture(u_noise,vUv*1.5+time*0.1);
    //   float anim = sin(time*5.0-mask.g*10.0-noise.r+vUv.x*3.+vUv.y*2.7)*0.5+0.5;
    //   float anim2 = sin(time*5.0-mask.r*10.0-noise.r)*0.5+0.5;
    //   float inv = 1.0-mask.g;
    //   float invint = 1.0-mask.r;
    //   float outterGlow = (1.0-smoothstep(inv-.5,inv,anim+noise.r*.5))*pow(inv,3.)+pow(inv,4.0);
    //   float lines = smoothstep(.6,1.,sin((noise2.r*2.0+time)*3.14158*2.0));
    //   lines = lines*(1.0-pow(mask.r,4.0)) + pow(lines,20.0)*.2;
    //   float innerGlow = (((1.0-smoothstep(invint-.5,invint,anim2+noise.r*.5))*pow(invint,3.)+pow(invint,4.0))+(lines*mask.b))*.5;
    //   float glow = abs(mix(outterGlow,innerGlow,mask.b));
    //   vec4 content = map;
    //   //   vec4 color = mix(vec4(oGlow.rgb,0),content,mask.b);
    //   vec4 color = mix(vec4(maskedImage.rgb,0),content,mask.b);
    //   //   vec4 color = mix(vec4(vec3(1),0),content,mask.b);
    //   color = mix(color,oGlow,glow);
    //   vec4 outputImage = mix(color, map, transition);

    //   gl_FragColor = outputImage;
    // } else {
      vec4 map = texture(u_map,v_uv);
      vec4 maskedImage = vec4(map.rgb, qmask.a);
      vec4 oGlow = vec4(0.9,0.5,0.3,0.5);
      float world = 3.0;
      vec4 mask = bMask;
      vec4 noise = texture(u_noise, vUv*3.7+time*0.1);
      vec4 noise2 = texture(u_noise,vUv*1.5+time*0.1);
      float anim = sin(time*5.0-mask.g*10.0-noise.r+vUv.x*3.+vUv.y*2.7)*0.5+0.5;
      float anim2 = sin(time*5.0-mask.r*10.0-noise.r)*0.5+0.5;
      float inv = 1.0-mask.g;
      float invint = 1.0-mask.r;
      float outterGlow = (1.0-smoothstep(inv-.5,inv,anim+noise.r*.5))*pow(inv,3.)+pow(inv,4.0);
      float lines = smoothstep(.6,1.,sin((noise2.r*2.0+time)*3.14158*2.0));
      lines = lines*(1.0-pow(mask.r,4.0)) + pow(lines,20.0)*.2;
      float innerGlow = (((1.0-smoothstep(invint-.5,invint,anim2+noise.r*.5))*pow(invint,3.)+pow(invint,4.0))+(lines*mask.b))*.5;
      float glow = abs(mix(outterGlow,innerGlow,mask.b));
      vec4 content = map;
      //   vec4 color = mix(vec4(oGlow.rgb,0),content,mask.b);
      vec4 color = mix(vec4(maskedImage.rgb,0),content,mask.b);
      //   vec4 color = mix(vec4(vec3(1),0),content,mask.b);
      color = mix(color,oGlow,glow);


      vec4 outputImage = mix(color, map, transition);

      gl_FragColor = outputImage;


  float alpha = sin(time*5.)*.5+.5; //periodo de 0 a 1

  if (hMask.r>0.) {
    float redChannel = hMask.r;
    gl_FragColor = mix(outputImage, outputImage*1.4, alpha);
  }

  if (hMask.g>0.) {
    float greenChannel = hMask.g*.25;
    gl_FragColor = mix(outputImage, vec4(1.,1.,1.,1.), alpha*greenChannel);
  }
  // }
}