import './style.scss';
import App from './Experience/App.js';

document.addEventListener('DOMContentLoaded', () => {
    
    
    /*** Envío a la la API ***/
    /* const url = `${import.meta.env.VITE_API_LEADS_ENDPOINT}/api/leads`;
    
    const data = {
        first_name: 'Roberto',
        last_name: 'Perez',
        email: 'rperez@test.com',
        phone: '1234567890'
    };

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        return response.json(); // Convertir la respuesta a JSON
    })
    .then(data => {
        if(data.error){
            console.log('Hubo un error:', data.message);
        }else{
            console.log('Salió todo bien');
        }
    })
    .catch(error => {
        console.error('Error:', error); // Manejar errores
    }); */
    /*************************/

    new App();
});
