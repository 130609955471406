import { gsap } from 'gsap';
import EventEmitter from '../Utils/EventEmitter';

const url = `${import.meta.env.VITE_API_LEADS_ENDPOINT}/api/leads`;


export default class DataFormMobile extends EventEmitter {
    constructor() {
        super();

        this.page = document.getElementById('dataFormMobile');

        this.continueButton = document.getElementById('dataFormButton');
        this.dataFormMessage = document.getElementById('dataFormMessage');
        this.name = document.getElementById('first_name');
        this.last_name = document.getElementById('user_name');
        this.email = document.getElementById('user_email');

        this.initAnimations();

        this.continueButton.onclick = (event) => {
            event.preventDefault();
            this.validateForm();
        };
    }

    show() {
        this.page.classList.add('section--active');
        // console.log('Show section Data Form Mobile');

        this.playAnimation();
    }

    hide() {
        this.page.classList.remove('section--active');
        // console.log('Hide section Data Form Mobile');
    }

    initAnimations() {
        this.tl = gsap.timeline({
            paused: true,
            defaults: { duration: 1, ease: 'power2.in' },
            onComplete: this.onAnimationComplete.bind(this)
        });

        this.tl
            .from(this.page.querySelector('.form__title'), {
                duration: 0.5,
                ease: 'power2.out',
                opacity: 0,
                y: 10
            })
            .from(this.page.querySelectorAll('.form__group'), {
                duration: 0.5,
                ease: 'power2.out',
                opacity: 0,
                y: 10,
                stagger: 0.2
            }, "-=0.2")
            .from(this.page.querySelector('.btn'), {
                duration: 0.5,
                ease: 'power2.out',
                opacity: 0,
                y: 10
            }, "-=0.2");
    }

    onAnimationComplete() {
        // console.log('Animation complete');
    }

    playAnimation() {
        this.tl.play();
    }

    async validateForm() {
        const name = this.name.value.trim();
        const last_name = this.last_name.value.trim();
        const email = this.email.value.trim();
        let valid = true;
        let errorMessage = '';


        // Validación del nombre
        if (!name) {
            valid = false;
            errorMessage += 'Name is required. ';
            this.name.classList.add('form__control--error');
        } else {
            this.name.classList.remove('form__control--error');
        }

        if (!last_name) {
            valid = false;
            errorMessage += 'Last Name is required. ';
            this.name.classList.add('form__control--error');
        } else {
            this.name.classList.remove('form__control--error');
        }

        if (valid) {
            if (!email) {
                valid = false;
                errorMessage += 'Email is required. ';
                this.email.classList.add('form__control-error');
            } else if (!this.validateEmail(email)) {
                valid = false;
                errorMessage += 'Please enter a valid email address. ';
                this.email.classList.add('form__control-error');
            } else {
                this.email.classList.remove('form__control--error');
            }
        } else {
            this.email.classList.remove('form__control--error');
        }

        if (valid) {
            this.dataFormMessage.textContent = '';

            const data = {
                first_name: name,
                last_name: last_name,
                email: email
            };

            const bypass = document.URL.split('#').length > 1 ? true : false;
            const bypassCheck = document.URL.split('#')[1];

            if(bypass && bypassCheck === 'bypass'){

                const preloaderElement = document.getElementById('sitePreloader');
                preloaderElement.style.display = 'flex';
    
                setTimeout(()=>{
                    preloaderElement.style.display = 'none';
                    this.trigger('formValid', { name, email })
                },3000)
                return
            }

            const preloaderElement = document.getElementById('sitePreloader');
            preloaderElement.style.display = 'flex';
            this.apiCall = await this.apiRequest(data)



            if(this.apiCall.error){
                this.dataFormMessage.textContent = this.apiCall.message;
                this.dataFormMessage.textContent = 'Error validating data, please try again later. '

            } else {
                preloaderElement.style.display = 'none';

                this.trigger('formValid', { name, email })

            }
            


        } else {
            this.dataFormMessage.textContent = errorMessage;
        }
    }

    validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    apiRequest(data){


        return new Promise((resolve) => {

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(response => {
                return response.json(); // Convertir la respuesta a JSON
            })
            .then(data => {
                if(data.error){
                    resolve(data);

                    // console.log('Hubo un error:', data.message);
                }else{
                    resolve(data);
                }
            })
            .catch(error => {
                console.error('Error:', error); // Manejar errores
            });

        });




    }
    
}