import * as THREE from 'three'

import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import Stage from './Stage/Stage.js'

import CameraControl from './CameraControl.js'

import Home from './Home/Home.js'
import UserCam from './UserCam.js'
import EventEmitter from './Utils/EventEmitter.js'
import App from './App.js'
import Engine from './Utils/Engine.js'

import hotspotsJSON from '../hotspots.json'

let instance = null

export default class WebExperience extends EventEmitter
{
    constructor()
    {
        super()
        // Singleton
        if(instance)
        {
            return instance
        }
        instance = this

        // App
        this.app = new App()

        this.active = false

        // Engine Setup
        this.engine = new Engine()
        this.canvas = this.engine.canvas
        this.sizes = this.engine.sizes
        this.time = this.engine.time
        this.scene = this.engine.scene
        this.camera = this.engine.camera
        this.renderer = this.engine.renderer

        this.clickable = []

        this.stage = new Stage()
        
        this.home = new Home()
        // console.log('HOME CREATED');

        this.userCam = new UserCam();

        this.activeScene = ''
        this.hotspotopen = false
        
        this.closeBtn = document.querySelector('.hotspot__close')
        
        this.closeBtn.onclick = (event) => {
            event.preventDefault();
            this.spotClose();
        };

        this.discoverBtn = document.querySelector('.btn-solid')
        
        this.discoverBtn.onclick = (event) => {
            event.preventDefault();
            this.actionItem();
        };

        

        this.sizes.on('resize', () =>
        {
            this.resize()
        })

        // Time runFrame event
        this.time.on('runFrame', () =>
        {
            this.update()
        })

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let location = urlParams.get('location')


        // this.userCam.init()
        // this.home.showScene();
        this.requestCameraControl()

        this.userCam.init()



    }

    requestCameraControl(){
        this.cameraControl = new CameraControl()
    }

    show(){
        this.activeScene = 'home'

        this.userCam.show()


        // this.userCam.on('mediaReceived', () => {
        // console.log('media received');
        this.canvas.style.display="block";
        this.active = true
        this.cameraControl.active = true
        this.home.showScene()
        // console.log(this.home);
        
        // this.home.homePanorama.show()
        this.sizes.updateSizes()
        this.showHomeLogo()
        setTimeout(() => {
            this.resize()
        }, 500);
        // })

    }
    testHome(){
        this.activeScene = 'home'

        this.userCam.show()

        this.canvas.style.display="block";
        this.active = true
        this.cameraControl.active = true
        setTimeout(() => {
            this.home.showScene()
            this.home.homePanorama.show()    
        }, 2000);
    }

    showStage(){
        this.activeScene = 'stage'

        this.home.hideScene()
        this.active = true
        this.cameraControl.active = true
        this.stage.showScene()
        this.stage.stageObjects.position.z = 10
        this.stage.stageObjects.position.y = -20

        // <a href="#" id="btnBack" class="btn-back"><!-- add/remove class btn-back--active to show/hide -->
        this.backButton = document.getElementById('btnBack')
        this.backButton.classList.add('btn-back--active');
        this.backButton.addEventListener('click', this.clickedBackButton.bind(this));

    }

    clickedBackButton(){
        this.backButton.classList.remove('btn-back--active');
        this.home.spot_active = ''
        this.stage.hideScene()
        this.show()
    }


    testStage(){
        this.activeScene = 'stage'

        this.userCam.show()
        this.active = true
        this.cameraControl.active = true
        this.stage.showScene()
    }

    hide(){
        this.activeScene = ''

        this.active = false
        this.cameraControl.active = false
        this.canvas.style.display="none";
    }

    pause(){
        this.active = false
    }
    resume(){
        this.active = true
    }

    resize()
    {
        // console.log('WebExperience resize');
        // this.canvas.style.width = window.innerWidth;
        // this.canvas.style.height = `${window.innerHeight}px`;

        // console.log(this.canvas);
    
        this.camera.resize()
        this.renderer.resize()
        // this.videoScreen.resize()
        if(this.userCam){
            this.userCam.resize()
        }
    }

    update()
    {
        if(!this.active) return
        // console.log('update');

        this.stage?.update()
        this.home?.update()

        this.camera.update()
        this.renderer.update()
        this.cameraControl?.update()

    }

    spotClicked(name){
        console.log(`Clicked: ${name}`);
        this.preloader.show('hotspot', 2)
    }

    spotPortal(){
        if(this.activeScene != 'home') return
        this.setHotspot(hotspotsJSON.hotspots[3])
    }

    setHotspot(hotspotInfo){
        // console.log('webex set hotspot');
        
        if(this.hotspotopen) return
        this.hotspotopen = true
        this.hotspotImage = document.getElementById('hotspotImage')
        this.hotspotTitle = document.querySelector('.hotspot__title')
        this.hotspotDescription = document.querySelector('.hotspot__description')
        
        // console.log(this.hotspotImage);
        this.hotspotTitle.innerHTML = hotspotInfo.title
        this.hotspotDescription.innerHTML = hotspotInfo.description
        this.hotspotImage.src = hotspotInfo.image
        // console.log(hotspotsJSON.hotspots[0]);
        // console.log(document.querySelector('.hotspot__title'));

        // 1. Add class hotspot--active to hotspot element
        // 2. Wait 50ms (setTimeout)
        // 3. Add class hotspot--show to hotspot element
        this.hotspot = document.querySelector('.hotspot')
        this.hotspot.classList.add("hotspot--active");
        setTimeout(() => {
            this.hotspot.classList.add("hotspot--show");
        }, 50);
        // this.experience.behind.showPage()
        this.pause()

    }

    actionItem(){
        // console.log('webex cationitem');

        console.log(this.home.spot_active);
        switch (this.home.spot_active) {
            case 'spot_portal':
                this.app.soundButton.hide()
                this.pause()
                this.app.videoARMobile.show()        
                this.app.videoARMobile.on('videoEnded', ()=>{
                    this.app.onMobileARVideoEnded()
                    this.app.soundButton.show()
        
                });
                break;
            case 'spot_corona':
                // this.app.soundButton.hide()
                // this.pause()
                // this.app.videoHidden.show()        
                // this.app.videoHidden.on('videoEnded', ()=>{
                //     this.app.onMobileVideoHiddenEnded()
                //     this.app.soundButton.show()
                //     this.show()
                // });
                this.app.soundButton.fastMute()
                window.open('https://www.coronausa.com/pages/buy-online/', '_blank')

                break;
            case 'spot_radio':
                this.app.soundButton.fastMute()
                window.open('https://corona.pesopluma.com/playlist/', '_blank')
                break;
            case 'spot_frame':
                this.app.soundButton.fastMute()
                window.open('https://www.pesopluma.com/#datess', '_blank')
                break;
            case 'spot_hand':
                this.app.soundButton.fastMute()
                window.open('https://www.billboard.com/video/peso-pluma-reveals-secrets-to-living-the-fine-life/', '_blank')
                break;
            default:
                console.log(this.home.spot_active);
                break;
        }
        setTimeout(()=>{
            this.spotClose()
            this.home.spot_active = ''
        }, 500)

    }

    spotClose(){
        // console.log('webex spotclose');

        this.hotspot.classList.add("hotspot--hide");
        setTimeout(() => {
            this.hotspot.classList.remove("hotspot--show");
            this.hotspot.classList.remove("hotspot--active");

        }, 50);
        this.home.spot_active = ''
        this.resume()
        this.hotspotopen = false
    }

    spotBobble(){
        if(this.activeScene != 'home') return
        // console.log('bobble clicked');
        this.setHotspot(hotspotsJSON.hotspots[2])
    }
    spotCorona(){
        if(this.activeScene != 'home') return

        this.app.soundButton.hide()
        this.pause()
        this.app.videoHidden.show()        
        this.app.videoHidden.on('videoEnded', ()=>{
            this.app.onMobileVideoHiddenEnded()
            this.app.soundButton.show()
            this.show()
            this.setHotspot(hotspotsJSON.hotspots[0])

        });

        // this.setHotspot(hotspotsJSON.hotspots[0])
    }
    spotFrame(){
        if(this.activeScene != 'home') return
        this.setHotspot(hotspotsJSON.hotspots[1])
    }
    spotRadio(){
        if(this.activeScene != 'home') return
        this.setHotspot(hotspotsJSON.hotspots[4])
    }
    spotHand(){
        if(this.activeScene != 'home') return
        this.setHotspot(hotspotsJSON.hotspots[5])
    }


    showHomeLogo(){
        // <div class="main-logo"><!-- add/remove class main-logo--active to show/hide -->
        this.homeLogo = document.querySelector('.main-logo')
        this.homeLogo.classList.add('main-logo--active');
    }

    hideHomeLogo(){
        this.homeLogo = document.querySelector('.main-logo')
        this.homeLogo.classList.add('main-logo--active');
    }



}