import * as THREE from 'three';

import panoramaVertexShader from './shaders/panorama/vertex.glsl'
import panoramaFragmentShader from './shaders/panorama/fragment.glsl'

import brandingVertexShader from './shaders/branding/vertex.glsl'
import brandingFragmentShader from './shaders/branding/fragment.glsl'


import { gsap } from 'gsap'
import WebExperience from '../WebExperience';
// import EventEmitter from '../Utils/EventEmitter';


export default class HomePanorama {
  constructor(_texture, _maskBottle, _noise, _bmask, _hMask, _branding) {

    this.experience = new WebExperience();
    this.scene = this.experience.scene;
    this.time = this.experience.time;


    this.geometry = new THREE.SphereGeometry( 100, 60, 40 );
    this.geometry.scale( - 1, 1, 1 );
    this.geometry.doubleSided = true;

    this.texture = _texture;
    this.maskBottle = _maskBottle
    this.noise = _noise
    this.bMask = _bmask
    this.hMask = _hMask

    this.texture.colorSpace = THREE.SRGBColorSpace;

    const uniforms = {
        u_time: { value: 0.0 },
        u_map: { value: this.texture },
        u_maskBottle: { value: this.maskBottle },
        u_bMask: { value: this.bMask },
        u_hMask: { value: this.hMask },
        u_noise: { value: this.noise },
        u_mix: { value: 1.0 },
        u_opacity: { value: 0.0 },
        u_transition: { value: 0.0 },
        u_scale: { value: 0.1 },
        u_scale_loop: { value: 1.0 },
      }
    

    this.shaderMaterial = new THREE.ShaderMaterial({
        vertexShader: panoramaVertexShader,
        fragmentShader: panoramaFragmentShader,
        uniforms,
        side: THREE.FrontSide,
        transparent: true,
        depthTest: true, 
        depthWrite: false,
      });

    this.mesh = new THREE.Mesh( this.geometry, this.shaderMaterial );
    this.mesh.name = 'panoramaMesh'


    // branding geometry
    this.brandingGeometry = new THREE.PlaneGeometry(1, 1, 16, 64)
    this.brandingGeometry.scale(1, 1, 1)
    // this.brandingGeometry.rotateZ(0.12)
    this.brandingGeometry.rotateY(Math.PI/2)

    // Material
    this.brandingTexture = _branding
    this.brandingOptions = {
      alpha: 1.0,
      scale: 2.5,
    }

    this.brandingMaterial = new THREE.ShaderMaterial({
      vertexShader: brandingVertexShader,
      fragmentShader: brandingFragmentShader,
      uniforms:
      {
          uTime: new THREE.Uniform(0),
          uBranding: { value: this.brandingTexture },
          u_alpha: { value: this.brandingOptions.alpha },
        },
      side: THREE.DoubleSide,
      transparent: true,
      depthWrite: true,
      depthTest: false,
      // wireframe: true
  })


    this.branding = new THREE.Mesh(this.brandingGeometry, this.brandingMaterial)
    this.branding.position.x = -2
    this.branding.position.y = -.9
    this.branding.position.z = -1

    this.branding.scale.set(0,0,0)
    
    this.branding.renderOrder = 102

    this.mesh.add(this.branding)





    // setTimeout(() => {
    //   console.log('umix');
    //     gsap.to(this.mesh.material.uniforms.u_opacity, { 
    //         duration: 1, 
    //         value: 1.0, 
    //      })
    //     gsap.to(this.mesh.material.uniforms.u_scale, { 
    //         duration: 4, 
    //         value: 1.0, 
    //      })
    // }, 4000);
    // setTimeout(() => {
    //   console.log('umixback');
    //     gsap.to(this.mesh.material.uniforms.u_mix, { 
    //         duration: 1, 
    //         value: 0.0,
    //      })
    // }, 6000);


    // gsap.to(this.mesh.material.uniforms.u_scale_loop, {
    //   duration: 1,
    //   value: 1.1,
    //   repeat: -1,
    //   yoyo: true,
    //   ease: "sine.inOut",
    // });



  }

  hide(){
    console.log('homepanorama hide');
    // gsap.set(this.mesh.material.uniforms.u_visibility, { value: 0 })
  }

  show(){
    // console.log('homepanorama show');
    // gsap.set(this.mesh.material.uniforms.u_visibility, { value: 1 })

    var tl1 = gsap.timeline( {
      repeat: 0,
      delay: 3,

    } );
    tl1.to(this.branding.scale, {
      x: this.brandingOptions.scale,
      y: this.brandingOptions.scale,
      z: this.brandingOptions.scale,
      duration: 2.5,
      ease: "expo.out",
    });
    tl1.to(this.branding.scale, {
      duration: 2,
      x: 25,
      y: 25,
      z: 25,
      ease: "expo.out",
    },
    3.5
    );
    tl1.to(this.branding.position, {
      duration: 2,
      x: 0,
      ease: "expo.out",
    },
    3.5
    );
    tl1.to(this.brandingOptions, {
      duration: 1.5,
      alpha: 0,
      ease: "expo.out",
      onComplete: () => {
        this.mesh.remove(this.branding)
      }
    },
    3
    );



    var tl2 = gsap.timeline( {
      repeat: 0,
      delay: 0.5,
    } );
    tl2.to(this.mesh.material.uniforms.u_scale, {
      duration: 1,
      value: 1,
      ease: "expo.out",
    });
    tl2.to(this.mesh.material.uniforms.u_opacity, {
      duration: 3,
      value: 1,
      ease: "expo.out",
    });
    tl2.to(this.mesh.material.uniforms.u_scale, {
      duration: 3,
      value: 20,
      ease: "expo.out",
    },
    6
    );
    tl2.to(this.mesh.material.uniforms.u_mix, {
      duration: 1,
      value: 0,
    },
    7
    );
    tl2.to(this.mesh.material.uniforms.u_transition, {
      duration: 1,
      value: 1,
    },
    7
    );

    var tl = gsap.timeline( {
      repeat:-1, 
    } ); 
    tl.to(this.mesh.material.uniforms.u_scale_loop, {
      duration: 1,
      value: 1.1,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
    });


  }

  update(){
    // console.log('homepanorama update');
    // console.log(this.mesh.material.uniforms.u_mix.value);

    this.mesh.material.uniforms.u_time.value = this.experience.time.elapsed / 1000
    this.branding.material.uniforms.u_alpha.value = this.brandingOptions.alpha
  }

}
