import * as THREE from 'three';
import HomeEnvironment from './HomeEnvironment.js'
import Resources from '../Utils/Resources.js'

import homeSources from './HomeSources.js'
import HomePanorama from './HomePanorama.js'

import Hotspot from '../../Global/Hotspot.js'

import ModelElement from '../../Global/Elements/ModelElement.js';
import WebExperience from '../WebExperience.js';
import IceSmoke from './IceSmoke.js';
import Engine from '../Utils/Engine.js';


export default class Home
{
    constructor()
    {

        this.engine = new Engine()
        this.time = this.engine.time
        this.camera = this.engine.camera
    
        this.experience = new WebExperience()
        this.scene = this.experience.scene

        this.homeResources = new Resources(homeSources)

        this.homeObjects = new THREE.Group()

        this.hotspots = []
        this.mousePosition = new THREE.Vector2()
        this.raycaster = new THREE.Raycaster();
        let target = this

        this.isHomeActive = false
    

        // console.log(this.experience.cameraControl);
        // this.homeObjects.rotation.y = this.experience.cameraControl.docGranted ? 0 : -Math.PI / 2
        // this.homeObjects.rotation.y = -Math.PI / 2
        
        this.homeResources.on('ready', () =>
        {

            // Environment
            this.homeEnvironment = new HomeEnvironment(this.homeResources.items.panoramaImage)
            this.homeObjects.add(this.homeEnvironment.sunLight)

            this.homePanorama = new HomePanorama(this.homeResources.items.panoramaImage, this.homeResources.items.bottleMask, this.homeResources.items.panoramaNoise, this.homeResources.items.bMask, this.homeResources.items.hMask, this.homeResources.items.coronaBranding)
            this.homeObjects.add(this.homePanorama.mesh)
            // console.log('HOME PANORAMA CREATED');

            this.iceSmoke = new IceSmoke(this.homeResources.items.perlin)
            this.homeObjects.add(this.iceSmoke.container)

            // this.panorama = new HomeBG('homePanorama', this.homeResources.items.panoramaModel, this.homeResources.items.panoramaImage)
            // this.homeObjects.add(this.panorama.panoramaModel.model)

            // bobblehead
            this.addBobbleHead()

            //hotspots
            this.addHotspots()

        })

        // addEventListener('mousemove', e =>{
        //     if(!this.experience.active) return
      
        //     target.mousePosition.x = (e.clientX / window.innerWidth) * 2 - 1;
        //     target.mousePosition.y = -(e.clientY / window.innerHeight) * 2 + 1;
      
      
        // })
    }


    update()
    {
        if(this.bobble) {
            this.bobble.update()
            this.bobble.updateAnim()
        }
        if(this.homePanorama) {
            // console.log('home homepanorama update');
            this.homePanorama.update()        
        }
    }

    hide(){
        this.experience.camera.deactivateControls()
        this.scene.remove(this.homeObjects)
        this.isHomeActive = true

    }

    hideScene(){
        this.scene.remove(this.homeObjects)
        this.isHomeActive = true

        // // this.experience.camera.deactivateControls()
        // if(homeConfig.preloader){
        //     this.experience.preloader.show()
        //     this.experience.active = false
        //     this.experience.preloader.on('pHidden', () =>
        //     {
        //         this.scene.remove(this.homeObjects)
        //     })
        // } else {
        //     this.scene.remove(this.homeObjects)
        // }
        
    }
    showScene(){
        this.scene.add(this.homeObjects)
        this.homePanorama.show()
        setTimeout(() => {
            this.growBobble()
            this.isHomeActive = true

            this.spot_portal.showDot()
            this.spot_frame.showDot()
            this.spot_hand.showDot()
            this.spot_radio.showDot()

            this.iceSmoke.showSmoke()
        }, 8000);
    }

    addBobbleHead(){
        // BobbleHead

        let bobbleAnimations = homeSources.find(source => source.name === "bobble")
        // console.log(bobbleAnimations.animations);
        // console.log(this.homeResources.items.bobble);
        // console.log(this.homeResources);
        this.bobble = new ModelElement('bobble', this.homeResources.items.bobble, bobbleAnimations.animations)
        this.bobble.setPosition(-16,-10,-16);

        // console.log('coronaBottle set scale 0');
        // console.log(this.coronaBottle);
        // this.bobble.setScale(4.8, 1)
        this.bobble.setScale(0, 1)

        this.homeObjects.add(this.bobble.element);

        this.bobble.setRotation(20);

        this.bobble.playAnimation('Cylinder.002Action.001')
    }

    growBobble(){
        this.bobble.setScale(4.8, 40)
        // var tl = gsap.timeline( {
        //     repeat:0, 
        //     } ); 
        //     tl.to(this.bobble.element.scale, {
        //     duration: 1,
        //     value: 1.1,
        //     repeat: -1,
        //     yoyo: true,
        //     ease: "sine.inOut",
        //     });
        

    }


    addHotspots(){
        //spots
        this.spots = [
            {
                name: 'spot_radio',
                degX: -93,
                degY: -12,
                width: 14,
                height: 8,
                position: {
                    x: 0,
                    y: -2,
                    z: 0
                },
            }
        ]
        this.spot_active = ''

        // // spot_radio
        // this.spot_radio = new Hotspot('spot_radio', -93, -12, 14, 8);
        // this.spot_radio.setPosition(0,-2,0)
        // this.homeObjects.add(this.spot_radio.element);
        // this.spot_radio.on('hotspot_click', () =>
        // {
        //     if(this.spot_active != '') return
        //     this.spot_active = 'radio'
        //     this.experience.merch.showPage()
        //     this.experience.pause()
        // })
        // this.experience.clickable.push(this.spot_radio.element)

        // spot_portal
        this.spot_portal = new Hotspot('spot_portal', -157, 5, 10, 12);
        this.spot_portal.setPosition(0,-2,0)
        this.homeObjects.add(this.spot_portal.dotElement);
        this.spot_portal.moveDot(0.95,0.4)

        this.homeObjects.add(this.spot_portal.element);
        // this.spot_portal.on('hotspot_click', () =>
        // {
        //     if(this.spot_active != '') return
        //     this.spot_active = 'spot_portal'
        //     this.experience.spotPortal()

        // })
        this.hotspots.push(this.spot_portal.element)
        // this.experience.clickable.push(this.spot_portal.element)


        // spot_bobble
        this.spot_bobble = new Hotspot('spot_bobble', -44, -5, 9, 25);
        this.spot_bobble.setPosition(0,-2,0)
        // this.hotspots.push(this.spot_bobble.element)

        // spot_corona
        this.spot_corona = new Hotspot('spot_corona', 90, 4, 7, 7);
        this.spot_corona.setPosition(0,-2,0)
        this.homeObjects.add(this.spot_corona.element);
        // this.homeObjects.add(this.spot_corona.dotElement);
        this.hotspots.push(this.spot_corona.element)

        // spot_frame
        this.spot_frame = new Hotspot('spot_frame',42, -28, 12, 20);
        this.spot_frame.setPosition(0,-2,0)
        this.homeObjects.add(this.spot_frame.element);
        this.homeObjects.add(this.spot_frame.dotElement);
        this.spot_frame.moveDot(-0.2,1.1)
        this.hotspots.push(this.spot_frame.element)

        // spot_radio
        this.spot_radio = new Hotspot('spot_radio', -93, -12, 14, 8);
        this.spot_radio.setPosition(0,-2,0)
        this.homeObjects.add(this.spot_radio.element);
        this.homeObjects.add(this.spot_radio.dotElement);
        this.spot_radio.moveDot(0.25,.5)

        this.hotspots.push(this.spot_radio.element)

        // spot_hand
        this.spot_hand = new Hotspot('spot_hand', 133, -5, 26, 16);
        this.spot_hand.setPosition(0,-2,0)
        this.spot_hand.moveDot(0.6,0.7)

        this.homeObjects.add(this.spot_hand.element);
        this.homeObjects.add(this.spot_hand.dotElement);
        this.hotspots.push(this.spot_hand.element)
        

        let target = this

        addEventListener('click',e =>{

            if (!this.isHomeActive) return
            // console.log('click home active');
            

            // console.log('click from home');
            // if(!this.experience.active) return
            // if(this.active) return
      
      
            target.mousePosition.x = (e.clientX / window.innerWidth) * 2 - 1;
            target.mousePosition.y = -(e.clientY / window.innerHeight) * 2 + 1;
          // console.log("X = "+target.mousePosition.x);
          // console.log("Y = "+target.mousePosition.y);
            this.raycaster.setFromCamera(target.mousePosition,this.camera.instance);
            // const intersects = this.raycaster.intersectObjects( this.experience.scene.children );
            const intersects = this.raycaster.intersectObjects( this.hotspots );
      
            // console.log(this.experience.clickable);
            // console.log(this.experience.scene.children);
            
          // for ( let i = 0; i < intersects.length; i ++ ) {
      
      
          // console.log(intersects[0].object.name);
          // console.log(this.mesh.name);
      
          if(intersects.length>0){
            console.log(intersects[0].object.name);
            switch (intersects[0].object.name) {
                case 'spot_bobble':
                    // if(this.spot_active != '') return
                    this.spot_active = 'spot_bobble'
                    this.experience.spotBobble()
                    break;
                case 'spot_portal':
                    // if(this.spot_active != '') return
                    this.spot_active = 'spot_portal'
                    this.experience.spotPortal()
                    break;
                case 'spot_corona':
                    // if(this.spot_active != '') return
                    this.spot_active = 'spot_corona'
                    this.experience.spotCorona()
                    break;
                case 'spot_frame':
                    // if(this.spot_active != '') return
                    this.spot_active = 'spot_frame'
                    this.experience.spotFrame()
                    break;
                case 'spot_radio':
                    // if(this.spot_active != '') return
                    this.spot_active = 'spot_radio'
                    this.experience.spotRadio()
                    break;
                case 'spot_hand':
                    // if(this.spot_active != '') return
                    this.spot_active = 'spot_hand'
                    this.experience.spotHand()
                    break;
                default:
                    // console.log(intersects[0].object.name);
                    break;
            }
            // if(intersects[0].object.name === this.mesh.name){
            //   // console.log(intersects[i]);
            //   // console.log('mesh click');
            //   // console.log(i);
            //   // console.log(`clicked ${this.name}`);
            //   // console.log(`clickedclickedclickedclickedclickedclickedclicked`);
            //   this.trigger('hotspot_click');
      
            // }
          }
            // console.log(intersects[i]);
      
            // intersects[ i ].object.material.color.set( 0xff0000 );
      
          // }
          })
      

    }

}